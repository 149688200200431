<template>
  <v-container>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="hsms"
          :search="search"
          show-expand
          item-key="id"
          class="elevation-1"
          :loading="loading"
          loading-text="Extrayendo a los hsms desde la base de datos, un momento..."
        >
          <template v-slot:top>
          <v-toolbar
              flat
            >
              <v-toolbar-title>HSM</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-autocomplete
              v-model="modelOrigen"
              class="mr-3"
              style="margin-top: 1.5em;"
              :items="itemsOrigen"  
               
              color="white"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="id"
              label="Origen de Envio"
              placeholder="Asistente"
              prepend-icon="mdi-robot"
              return-object
              @change="seleccionAsistente"
            ></v-autocomplete>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search" class="mr-3"
                  append-icon="mdi-magnify"
                  label="Buscar hsms"
                  single-line
                  hide-details
                ></v-text-field>
              <v-dialog
                v-model="dialog"
                persistent
                max-width="1010px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    :disabled=disableBtnAddHsm
                  >
                    Nuevo
                  </v-btn>
                </template>
                <v-card>
                <v-row style="margin: 0;"> 
                  <v-col class="col-6">
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span> 
                      </v-card-title>

                      <v-card-text>
                        <v-container> 
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                            >
                          <v-autocomplete
                            v-model="modelTipo"
                            class="mr-3" 
                            :items="itemsTipo"   
                            color="white"
                            hide-no-data
                            hide-selected
                            item-text="label"
                            item-value="id"
                            label="Formato del Hsm" 
                            return-object
                            @change="cambiarTipo"
                          ></v-autocomplete>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                            > <v-text-field
                                v-model="editedItem.nombre_hsm"
                                label="Nombre Hsm" 
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                            > <v-textarea
                                v-model="editedItem.mensaje"
                                label="Mensaje (Copiar directo de sinch)"
                                rows="1"
                                required
                              ></v-textarea>
                            </v-col> 
                            <v-col
                              cols="12"
                              sm="12"
                            > <v-text-field
                                v-model="editedItem.numero_parametros"
                                label="Numero de Parametros"
                                type="number"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                            > <v-text-field
                                v-model="editedItem.parametros_text"
                                label="Parametros( separar con , '¡coma!')" 
                                v-if="editedItem.numero_parametros>0"   
                                @keydown ="parametrosText"
                                @change ="parametrosText"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                            > 
                            <v-chip-group
                                active-class="primary--text"
                                column
                              >
                                <v-chip
                                  v-for="tag in arregloParametros"
                                  :key="tag"
                                >
                                  {{ tag }}
                                </v-chip>
                              </v-chip-group>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                            > <v-text-field
                                v-model="editedItem.intent"
                                label="Intent" 
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row> 
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="guardarHsm"
                          :loading="isLoading"
                        >
                          Guardar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col class="col-6" >
                    <div  class="col-12 d-flex justify-content-center">
                <div class="bgWA  ml-5  d-block" style="height: 100%;" >
                  <img class="bannerWA mt-3 " src="../../assets/imgs/bannerWA.png" alt="Banner"/>
                   
                  <div class="mensajeWA ml-5" >
                    <p class="textWA">{{ editedItem.mensaje }}</p>
                    <!-- <small class="horaWA"
                      >{{ hoy.toString().substr(16, 5) }} hrs</small
                    > -->
                  </div>
                </div>
              </div>

                  </v-col>
                </v-row>
              </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">¿Seguro de dar de baja este HSM?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                    <v-btn :loading="isLoadingDeleteBtn" color="blue darken-1" text @click="deleteItemConfirm">Aceptar</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
         </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div  class="col-12 col-md-6 d-flex justify-content-center">
                <div class="bgWA d-none d-md-block">
                 
                  <div v-if="item.mensaje" class="mensajeWA">
                    <p class="textWA">{{ item.mensaje }}</p>
                    <!-- <small class="horaWA"
                      >{{ hoy.toString().substr(16, 5) }} hrs</small
                    > -->
                  </div>
                </div>
              </div>
            </td>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
              
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <!-- <v-btn
              color="primary"
              @click="initialize"
            >
              Reiniciar
            </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row> 
  <v-snackbar
      :color="alertaModalsColor"
      v-model="alertaModals"
      :multi-line="true"
      :timeout="5000"
      centered
      outlined
      
    >
      {{ textAlertModals }}

      <template   >
        <v-btn 
        :color="alertaModalsColor"
          text  
          elevation="24"
           @click="alertaModals = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
 
</template>
<script>
import ApiService from '../../service/apis'
export default {
  name:'Hsms',
  data: () => ({
      disableBtnAddHsm : true,
      idHsmSelectect : 0 ,
      dialog: false,
      dialogDelete: false,
      selected: [{}],
      isLoading:false,
      isLoadingDeleteBtn : false,
      sesion:JSON.parse(sessionStorage.getItem('sesion')),
      search:null,
      textAlertModals:'',
      alertaPaso1:false, 
      alertaTypo : 'success',
      modelOrigen:{id:1,label:'Whatsapp Mexico'},
      typeMedia : '',
      modelTipo:null,
      alertaModalsColor : '',
      alertaModals : false,  
      itemsTipo :[
          {id:1,label:'TEXTO'},
          {id:2,label:'PDF'},
          {id:3,label:'IMAGEN (PNG)'},
          {id:4,label:'MP4'},
          {id:5,label:'BOTONES'},
          {id:6,label:'IMAGEN (JPG)'},
        
        ],
      arregloParametros:[],
      itemsOrigen :[{id:1,label:'Whatsapp Mexico'},{id:2,label:'Whatsapp Hemodialisis'},{id:3,label:'Whatsapp San Salvador'},{id:4,label:'Whatsapp PetCt'},{id:5,label:'Whatsapp Medicos'} ,{id:6,label:'Whatsapp Medicos Internacional'}  ],
      headers: [
        { text: 'HSM', align: 'start', value: 'nombre_hsm' },
        { text: 'Intent', value: 'intent' },
        { text: '# de parametros', value: 'numero_parametros' },
        { text: 'Estado', value: 'status' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      hsms: [],
      rol:[{_id:0,name:'Admin'},{_id:1,name:'Coordinador'}, {_id:2,name:'HSM'}],
      editedIndex: -1,
      show1:false,
      editedItem: {
        nombre_hsm: '',
        mensaje: '',
        campaignAlias: '',
        numero_parametros: 0,
        nombre_parametros: [],
        parametros_text :"",
        intent : ''
      },
      defaultItem: {
        nombre_hsm: '',
        mensaje: '',
        campaignAlias: '',
        numero_parametros: 0,
        nombre_parametros: [],
        parametros_text :"",
        intent : ''
      },
      loading:false
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Alta de nuevo HSM '  : 'Modificar HSM ' 
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.seleccionAsistente()
    },
    updated(){
      this.evento()
    },
    methods: {
      evento(){
        this.$emit('Hsm', this.selected)
      },
      seleccionAsistente(){  
        this.loading=true
        if(this.modelOrigen){
          this.disableBtnAddHsm = false
          ApiService.getHsms(this.modelOrigen.id).then(hsms =>{
            this.hsms = hsms.resp
            this.loading=false
          })
        }else{
          this.loading=false
          this.disableBtnAddHsm = true
          this.hsms = []
        }    
       
      },
      initialize () {
        this.loading=true
        ApiService.getHsms().then(hsms =>{
          this.hsms = hsms.resp
          this.loading=false
        })
      },
      editItem (item) {
        console.log(item)
        this.editedIndex = this.hsms.indexOf(item)
        this.editedItem = Object.assign({}, item)
        let textParams = item.nombre_parametros !=null ?item.nombre_parametros:false
        let varParametros = ''
        
        if(textParams){ 
          textParams.parametros_envio.forEach((element,i )=> { 
              if(i==0){
                varParametros += element
              }else{
                varParametros += ','+element
              }
             
          })
          this.arregloParametros = item.nombre_parametros.parametros_envio
        }  
        const tmpTypeMedia = item.typeMedia ?
                            item.typeMedia=='PDF'?   {id:2,label:'PDF'} :
                            item.typeMedia=='PNG'?  {id:3,label:'IMAGEN (PNG)'} :
                            item.typeMedia=='JPEG'?  {id:3,label:'IMAGEN (PNG)'} :
                            item.typeMedia=='JPG'?  {id:3,label:'IMAGEN (PNG)'} :
                            item.typeMedia=='MP4'?  {id:4,label:'MP4'}:
                            item.typeMedia=='BUTTON'?  {id:5,label:'BOTONES'}:{id:1,label:'TEXTO'}:{id:1,label:'TEXTO'}  

        this.modelTipo = tmpTypeMedia
        this.typeMedia = 'Tipo media actual : '
        this.editedItem.parametros_text = varParametros 
        
        this.dialog = true
        this.idHsmSelectect=item.id
        this.parametrosText()
      },
      deleteItem (item) {
        this.editedIndex = this.hsms.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
        this.idHsmSelectect=item.id
      }, 
      deleteItemConfirm () {
        this.isLoadingDeleteBtn = true
        let borrar = {
          id: this.idHsmSelectect,
          origen : this.modelOrigen.id
        }
        ApiService.actualizarStatusHsm(borrar).then(res =>{
          if(res){
            this.isLoadingDeleteBtn = false
            this.seleccionAsistente()
            this.closeDelete()
          }
        })
      },
      mensajeAlertaModals(tipo,texto){
        this.alertaModalsColor =tipo
        this.alertaModals =true
        this.textAlertModals =texto
      },
      guardarHsm(){

        if(this.editedItem.nombre_parametros==null){
          this.mensajeAlertaModals('error','Los parametros ingresados son diferentes ala cantidad especificada')
           return
        }

        if(this.editedItem.nombre_parametros.length!=this.editedItem.numero_parametros ){  
           this.mensajeAlertaModals('error','Los parametros ingresados son diferentes ala cantidad especificada')
           return
        }
        
        if(!this.modelTipo){  
           this.mensajeAlertaModals('error','El tipo de hsm no fue espesificado.')
           return
        }
         
        if(this.editedItem.nombre_hsm.trim().length<=0){  
           this.mensajeAlertaModals('error','El nombre del hsm no es valido.')
           return
        }

        if(this.editedItem.mensaje.trim().length<=0){  
           this.mensajeAlertaModals('error','El contenido del hsm no es valido.')
           return
        }
 

        this.isLoading=true
        const data = this.editedItem
        data.tipo = this.modelTipo.id
        data.origen = this.modelOrigen.id
        data.capturista_id = this.sesion.id

      if (this.editedIndex > -1) {
        data.id = this.idHsmSelectect
        ApiService.actualizarHsm(data).then(() =>{
          this.isLoading=false
            this.seleccionAsistente()
            this.close()
            this.mensajeAlertaModals('success','El hsm se actualizo correctamente.')
            this.idHsmSelectect = 0
        })
      }else{ 
        ApiService.guardarHsm(data).then(() =>{
          this.isLoading=false
            this.seleccionAsistente()
            this.close()
            this.mensajeAlertaModals('success','El hsm se registro correctamente.')
        })
      }
       
      },
      cambiarTipo(){
        console.log(this.modelTipo)
        this.typeMedia = 'Tipo Hsm : '+this.modelTipo.label
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1 
        })
      },
     parametrosText(){
        this.editedItem.parametros_text = this.editedItem.parametros_text.replace(/[^a-zA-Z0-9]/g,',').trim() 
        this.editedItem.nombre_parametros = this.editedItem.parametros_text.split(',').filter(Boolean) 
        this.arregloParametros = this.editedItem.nombre_parametros
        
        //if(this.editedItem.nombre_parametros.length!=this.editedItem.numero_parametros){  
         // this.mensajeAlertaModals('error','Los parametros ingresados son diferentes ala cantidad especificada')
        //}

       /* if(this.editedItem.nombre_parametros[this.editedItem.nombre_parametros.length].length<=0){
          console.log
          this.editedItem.nombre_parametros[this.editedItem.nombre_parametros.length].substring(0, this.editedItem.nombre_parametros[this.editedItem.nombre_parametros.length].length - 1);
          this.editedItem.nombre_parametros.pop()
          console.log(this.editedItem)
        }else{
          console.log('todo bien')
        }*/
        
      },
      save () {
        if (this.editedIndex > -1) {
          this.editedItem.uid=this.editedItem.email
          ApiService.editarHsms(this.editedItem).then(res =>{
            if(res){
              this.initialize()
              this.close()
            }
          })
        } else {
          this.editedItem.uid=this.editedItem.email
          ApiService.nuevoHsm(this.editedItem).then(res =>{
            if(res){
              this.initialize()
              this.close()
            }
          })
        }
      },
    },
}
</script>
<style>
.v-application .headline {
  letter-spacing: unset;
}
</style>